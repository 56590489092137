<template>
    <div>
        <p v-html="$t('faqTab.gettingStarted.paypal.website')" />
        <a :href="brandConfig.portalLink" target="_blank">{{ brandConfig.portalLink }}</a>
        <p class="mb-4" v-html="$t('faqTab.gettingStarted.paypal.optionTo')" />
        <img class="w-100" src="../assets/step6.png" :alt="$t('faqTab.gettingStarted.paypal.optionTo')" >
        <p class="my-4" v-html="$t('faqTab.gettingStarted.paypal.fillYour')" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters(['brandConfig']),
    }
}
</script>